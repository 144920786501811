// Goto https://www.w3schools.com/JSREF/jsref_tolocalestring.asp to find the date style for your country.
export const dateStyle= 'en-US';

//Change texts on right side of semi-colon only.
export const language =  {
    "profile":"Profile",
    "home":"Home",
    "about_us":"About Us",
    "booking_history":"Booking History",
    "logout":"Logout",
    "notification_title":"KabMan Notification",
    "select_reason":"Select Cancellation Reason",
    "no_cancel_reason":"No cancel reasons available.",
    "ok":"OK",
    "cancel":"Cancel",
    "select_country":"Select Country",
    "google_places_error":"Place ID to Location Error",
    "not_valid_rider":"This login is a valid user but not Rider",
    "new_booking_msg":"You Have A New Booking Request",
    "no_driver":"No Driver Found",
    "no_bookings":"No bookings available.",
    "booking_cancelled":"Booking is cancelled. ID : ",
    "no_cars":"No cars available.",
    "no_route":"No Route Found.",
    "firstname":"First Name",
    "lastname":"Last Name",
    "email":"Email",
    "phone":"Phone",
    "password":"Password",
    "otp":"OTP :",
    "profile_updated":"Profile Updated.",
    "proper_email":"Please enter email properly.",
    "submit":"Submit",
    "booking_title":"My Bookings",
    "booking_id":"Booking ID",
    "booking_date":"Booking Date",
    "car_type":"Car Type",
    "pickup_address":"Pickup Address",
    "drop_address":"Drop address",
    "booking_status":"Booking Status",
    "trip_cost":"Trip Cost",
    "payment_status":"Payment Status",
    "cancel_booking":"Cancel Booking",
    "select_car":"Select Car Type",
    "select_booking_type":"Select Booking Type",
    "book_now":"Book Now",
    "book_later":"Book Later",
    "book":"Book",
    "select_proper":"Please select properly.",
    "must_login":"Please Login for Booking",
    "pickup_location":"Pickup Location",
    "drop_location":"Drop Location",
    "booking_success":"Booking successful. Booking Id : ",
    "myaccount":"My Account",
    "login_signup":"Login / Sign Up",
    "login_success":"Login success. Need to complete registration.",
    "login_validate_error":"Email or Password is not correct.",
    "otp_validate_error":"OTP is not valid",
    "reset_pass_msg":"Reset password link sent to email.",
    "signin":"Sign In",
    "login":"Login",
    "register":"Register",
    "get_otp":"Get OTP",
    "verify_otp":"Verify OTP",
    "complete_registration":"Complete Registration",
    "forgot_pass_title":"Forgot Password",
    "forgot_pass_description":"Fill up your email address and request. Link will be sent on your email inbox.",
    "reset_password":"Reset Password",
    "email_tab":"Email Login",
    "phone_tab":"Phone Login",
    "booking_date_time":"Booking Data Time",
    "past_booking_error":"Ride Later is not avaialable for Past Datetime or within next 15 mins.",
    "booking_taken":"Booking is taken. Driver will be assigned 15 mins before your booking time. Your Booking ID is : ",
    "settings_error":"Unable to fetch settings.",
    "estimate":"Fare Estimate",
    "auth_error":"Auth Alert",
    "email_verify_message":"Please verify your email. Email is already sent to your Inbox.",
    "trip_start_time":"Trip start Time",
    "trip_end_time":"Trip End Time",
    "customer_name":"Customer Name",
    "vehicle_no":"Vehicle Number",
    "assign_driver":"Assign Driver",
    "discount_ammount":"Discount Amount",
    "payment_mode":"Payment Mode",
    "booking_text":"Bookings",
    "image":"Image",
    "name":"Name",
    "rate_per_km":"Rate Per Kilometer",
    "rate_per_hour":"Rate Per Hour",
    "min_fare":"Minimum Fare",
    "convenience_fee":"Convenience Fees",
    "convenience_fee_percent":"Convenience Fees (%)",
    "today_text":"Today",
    "gross_earning":"Gross Earnings",
    "admin_convenience_fee":"Our Earning",
    "this_month_text":"Month",
    "total":"Total",
    "real_time_driver_section_text":"Drivers Realtime",
    "allow_location":"Allow Location for the Realtime Map",
    "sign_in":"Sign in",
    "email_address":"Email Address",
    "sign_in_error":"Sign In Error. Please check Email and Password.",
    "device_type":"Device Type",
    "user_type":"User Type",
    "title":"Title",
    "body":"Body",
    "push_notification_title":"Push Notifications",
    "promo_name":"Promo Name",
    "description":"Description",
    "promo_type":"Promo Type",
    "promo_discount_value":"Promo Value",
    "max_limit":"Max Discount Allowed",
    "min_limit":"Minimum Order Value",
    "start_date":"Start Date",
    "end_date":"End Date",
    "promo_usage":"Promo Count Available",
    "promo_used_by":"Promo Used By Count",
    "promo_offer":"Promo and Offers",
    "refferal_bonus":"Referral Bonus",
    "update_failed":"Update Failed",
    "first_name":"First Name",
    "last_name":"Last Name",
    "mobile":"Mobile Number",
    "profile_image":"Profile Image",
    "vehicle_model":"Vehicle Model",
    "account_approve":"Account Approved",
    "driver_active":"Driver Active Status",
    "lisence_image":"License Image",
    "vehicle_number":"Vehicle Number",
    "wallet_balance":"Wallet Balance",
    "signup_via_refferal":"Signup Via Referral",
    "refferal_id":"Refferal Id",
    "all_user":"All Users",
    "notification_sent":" notifications sent.",
    "notification_sent_failed":"Notification sending failed.",
    "no_user_match":"No Users Matched",
    "no_user_matching":"No users found matching your selection.",
    "dashboard_text":"Dashboard",
    "user":"Users",
    "promo":"Promos",
    "driver_earning":"Driver Earning History",
    "earning_amount":"Earning Amount",
    "driver_name":"Driver Name",
    "driver_image":"Driver Image",
    "months":"Months",
    "year":"Years",
    "vehicle_type":"Vehicle Number",
    "lisence_no":"Lisence Number",
    "booking_discount_earning":"Booking Discount",
    "trip_cost_driver_share":"Driver share",
    "Gross_trip_cost":"Gross trip cost",
    "Discounts":"Discounts",
    "Customer_paid":"Customer paid",
    "Profit":"Profit",
    "earning_reports":"Earning Reports",
    "cash_payment_amount":"Cash payment amount",
    "card_payment_amount":"Card payment amount",
    "wallet_payment_amount":"Wallet payment amount",
    "payment_gateway":"Payment Gateway",
    "currency_symbol":"Currency Symbol",
    "currency_code":"Currency Code",
    "settings_title":"Settings",
    "settings_label1":"Cash",
    "settings_label2":"Wallet",
    "settings_label3":"Booking OTP",
    "settings_label4":"Driver Approval",
    "settings_label5":"Email Verification Mandatory",
    "payment_modes_title":"Payment Modes",
    "queue":"Busy",
    "createdAt":"Create Date",
    "security_title":"Security Settings",
    "panic_num":"Panic Dial Number",
    "addbookinglable":"Add Bookings",
    "select_user":"Select User",
    "other_settings":"Other Settings",
    "email_placeholder":"Email",
    "password_placeholder":"Password",
    "login_button":"Login",
    "register_link":"Register",
    "forgot_password_link":"Forgot or Reset Password?",
    "forgot_password_confirm":"Are you sure you want to reset password?",
    "facebook_login_button":"Facebook Login.",
    "password_blank_messege":"Password cannot be blank.",
    "password_alphaNumeric_check":"Password must consists of at least 1 alphanumeric characters and 8-15 length.",
    "password_complexity_check":"Password must be atleast 1 small-case letter, 1 Capital letter, 1 digit, 1 special character and 6-10 length.",
    "valid_email_check":"Please enter a valid email address.",
    "email_not_found":"Email not found in database",
    "valid_rider":"Please login with valid Rider Account",
    "facebook_login_error":"Login Error. ",
    "apple_signin_error":"Apple Signin is not set up in developer.appple.com or you have used the same email to signup already.",
    "forgot_password_success_messege":"A Password Reset Link sent to your email please check and reset your New Password.",
    "facebook_login_auth_error":"Facebook Login Error:",
    "google_login_auth_error":"Google Login Error:",
    "static_notification_title":"KabMan Notification",
    "modal_closed":"Modal has been closed.",
    "registration_title":"Registration",
    "first_name_placeholder":"First Name",
    "first_name_blank_error":"Please enter your first name.",
    "last_name_placeholder":"Last Name",
    "last_name_blank_error":"Please enter your last name.",
    "login_title":"Login with Mobile",
    "mobile_no_placeholder":"Mobile Number",
    "mobile_no_blank_error":"Please enter a valid mobile number.",
    "otp_error":"Please enter the correct OTP",
    "otp_here":"Please enter OTP here",
    "sending_otp":"Sending OTP...",
    "authorize":"Authorize",
    "request_otp":"Request OTP",
    "firebase_otp_error":"Authentication error with mobile OTP. Check your mobile number. You need to add International Country Code in front.",
    "spacer_message":"OR CONNECT WITH",
    "confrim_password_placeholder":"Confirm Password",
    "confrim_password_not_match_err":"Password and Confirm Password do not match",
    "referrer_id_placeholder":"Referral Id (Optional)",
    "referral_id":"Your Referral Id",
    "register_button":"Register Now",
    "email_exist_error":"The email address is already in use by another account.",
    "Account_linking_error":"Account linking error",
    "send_email_button":"Send Email",
    "book_your_ride_menu":"Book Your Ride",
    "profile_setting_menu":"Profile Settings",
    "my_wallet_menu":"My Wallet",
    "my_rides_menu":"My Rides",
    "about_us_menu":"About Us",
    "photo_upload_action_sheet_title":"Choose a Media Type ?",
    "camera":"Camera",
    "galery":"Gallery",
    "yes":"Yes",
    "network_request_failed":"Network request failed",
    "password_update_messege":"You Password successfully updated",
    "delete_account_modal_title":"Confrmation",
    "delete_account_modal_subtitle":"Do you want to delete your account ?",
    "profile_page_title":"My Profile",
    "my_wallet_title":"My Wallet Balance",
    "profile_page_subtitle":"Profile Details",
    "location_lebel":"Location",
    "language_lebel":"Language",
    "preffer_language":"English",
    "change_password_lebel":"Change Password",
    "delete_account_lebel":"Delete Account",
    "change_password_modal_title":"Change Password?",
    "old_password_placeholder":"Old Password",
    "new_password_placeholder":"New Password",
    "old_password_validation_error":"Please enter a old password",
    "new_password_validation_error":"Please enter a new password",
    "update_button":"Update Now",
    "update_profile_title":"Upadate Profile",
    "map_screen_where_input_text":"Where From ?",
    "map_screen_drop_input_text":"Drop Where ?",
    "driver_finding_alert":"Finding Drivers for you",
    "no_driver_found_alert_title":"Alert!",
    "no_driver_found_alert_messege":"Sorry,No Drivers found right now.Please try again later",
    "no_driver_found_alert_OK_button":"OK",
    "no_driver_found_alert_TRY_AGAIN_button":"Try Again",
    "pickup_and_drop_location_blank_error":"Select pickup and drop location",
    "pickup_location_blank_error":"Select pickup location",
    "drop_location_blank_error":"Select drop location",
    "car_type_blank_error":"Select car type",
    "congratulation":"Congratulations!",
    "refferal_bonus_messege_text":"Your referral bonus ammount -",
    "map_screen_title":"Home",
    "cab_selection_title":"Pick Your Travel Cab",
    "cab_selection_subtitle":"Tap to select car type and book now",
    "not_available":"Not Available",
    "book_now_button":"Book Now",
    "contact_details":"Contact Details",
    "my_wallet_tile":"My Wallet",
    "wallet_ballance":"Wallet Balance",
    "add_money":"Add Money",
    "transaction_history_title":"Wallet Transaction History",
    "successfully":"Successfully",
    "form_wallet":"form wallet",
    "Transaction_Id":"Transaction Id ",
    "ride_list_title":"My Rides",
    "no_car_assign_text":"No car assign",
    "not_found_text":"Not Found",
    "ride_details_page_title":"My Rides Details",
    "you_rated_text":"You Rated",
    "car_no_not_found":"Car number not assigned",
    "track_now_button":"Track Now",
    "bill_details_title":"Bill Details",
    "your_trip":"Your Trip",
    "discount":"Discount",
    "promo_apply":"(Promo Apply)",
    "grand_total":"Grand Total",
    "paynow_button":"Pay Now",
    "call_driver":"Call Driver",
    "booked_cab_title":"Booked Cab",
    "you_selected":"You Selected -",
    "driver_assign_messege":"Driver will Assign Soon..",
    "cancel_ride":"Cancel Ride",
    "rider_cancel_text":"Ride Cancel",
    "submit_rating":"SUBMIT RATING",
    "cancel_messege1":"Your ride with Booking Id",
    "cancel_messege2":"has been cancelled successfully",
    "dont_cancel_text":"Don't Cancel",
    "cancel_reason_modal_title":"What's Cancel Reason",
    "chat_title":"Chat",
    "send_button_text":"Send",
    "chat_input_title":"Type something nice...",
    "chat_not_found":"No chat history found",
    "no_details_error":"Please fill up all the details properly.",
    "payment_amound_error":"Payment amount is too small.",
    "invalid_card_details_error":"Card is not valid.",
    "success_payment":"Payment done sucessfully.",
    "confrimation":"Confrimation",
    "payment_alert":"Do you want to delete your card ?",
    "please_wait":"Calculating your fare",
    "enterCVV":"Please Enter Your CVV",
    "your_promo":"Your promo",
    "promo_exp":"Sorry!\nPromo code and Promo usage limit has expired.",
    "promo_limit":"Sorry!\nPromo usage limit over.",
    "promo_eligiblity":"SORRY!\nyou are not eligible for this promo.",
    "payment":"Payment",
    "bill_details":"Bill Details",
    "apply_promo":"Apply Promo",
    "remove_promo":"Remove Promo",
    "apply":"APPLY",
    "driver_near":"Driver Near You",
    "your_fare":"Your Fare",
    "promo_discount":"Promo Discount",
    "wallet_discount":"Wallet Discount",
    "payable_ammount":"Payable Amount",
    "use_wallet_balance":"Use Wallet Cash (Your balance is ",
    "pay_cash":"Pay with Cash",
    "pay_card":"Pay with Card",
    "proceed_pay":"Proceed to Pay",
    "save_card":"Your Saved Cards",
    "thanks":"Thanks For Rating",
    "skip":"Skip",
    "receipt":"Receipt",
    "rate_ride":"PLEASE RATE YOUR RIDE",
    "err":"Error!",
    "route_not_found":"Cannot found any route",
    "sorry":"Sorry",
    "multipleBooking":"KabMan currently doesn't support multiple bookings.",
    "driver_not_found":"We cannot assign driver at this moment. contact to adminstrator !!",
    "new_booking_request_push_notification":"You Have A New Booking Request",
    "fare_details":"Fare Details",
    "base_fare":"Base Fare",
    "convenience_free":"Convenience Fees",
    "total_fare":"Total Fare",
    "inclusive_tax":"(Inclusive all Taxes)",
    "nb":"N.B.: Your fare will be increased if toll,route and destination changes or if ride took longer due to traffic orother",
    "confrim_booking":"Confirm Booking",
    "estimate_fare_text":"This is your Estimate fare only",
    "done":"Done",
    "notification_headerText":"Notification",
    "notification_not_found":"No notification found",
    "track_cab":"Track Your Cab",
    "location_permission":"Location Access Permission",
    "ask_me_later":"Ask Me Later",
    "min_order_value":"Minimum order value-",
    "add_money_tile":"Add Money",
    "addMoneyTextInputPlaceholder":"Add Amount",
    "transactionFaildMsg":"Transaction Failed",
    "payWithCard":"Pay With Card",
    "CardPaymentAmount":"Pay by card -",
    "CashPaymentAmount":"Pay by cash -",
    "WalletPayment":"Pay by wallet -",
    "pay_mode":"Payment Mode",
    "Balance":"KabMan Balance",
    "android_channel":"KabMan Notifications",
    "write_message":"Write your message",
    "terms":"Terms & Conditions",
    "panic_text":"Panic Call",
    "panic_question":"Do you really want to make a Panic Call?",
    "email_login":"Login with Email",
    "register_email":"Register with Email",
    "book_later_button":"Book Later",
    "alert":"Alert",
    "country_blank_error":"Please select the country",
    "downloading_updates":"Downloading Updates...",
    "checking_updates":"Checking For Updates...",
    "fetching_data":"Fetching Data...",
    "loading_assets":"Loading Assets...",
    "login_page_title":"DRIVER LOGIN",
    "driver_account_approve_err":"Your account is not approved yet as driver.",
    "account_not_exsist":"Your account not exist as driver.",
    "driver_ride_complete_status":" Driver has Completed your ride. your booking id is",
    "on_trip":"On Trip",
    "complete_trip":"COMPLETE TRIP",
    "account_successful_done":"Your account has been successfully created and waiting for approve confirmation by admin.",
    "image_size_error":"Image Size should not be greater than 2mb.",
    "password_blank_error":"Password can't be blank.",
    "password_complexity_alphabet":"Password must consists of at least 1 alphanumeric characters and 8-15 length.",
    "password_complexity_complex":"Password must be atleast 1 Capital letter, small-case letter,1 digit and 1 special character with 6-10 length.",
    "driver_registration":"Driver Registration",
    "first_name_error":"Please enter your first name",
    "last_name_error":"Please enter your last name",
    "email_blank_error":"Please enter a valid email address",
    "confrim_password":"Confirm Password",
    "password_not_match":"Password does not match",
    "vehicle_model_name":"Vehicle Model Name",
    "vehicle_model_name_blank_error":"Vehicle Name Can't be blank",
    "vehicle_reg_no":"Vehicle Registration Number",
    "vehicle_number_blank_err":"Vehicle number Can't be blank",
    "upload_driving_lisence":"Upload your Driving License",
    "image_size_warning":"(Image size: Max 2 MB)",
    "reg_no":"Register Now",
    "update_profile":"Upadate Profile",
    "valid_mobile_number":"Please enter a valid mobile number",
    "update_now":"Update Now",
    "no_notification":"No notification found",
    "forgot_password":"Forgot Password?",
    "valid_email_err":"Please enter a valid email address",
    "send_email":"Send Email",
    "enter_code":"Enter the code",
    "change_password":"Change Password ?",
    "old_password":"Old Password",
    "old_password_err":"Please enter a old password",
    "new_password":"New Password",
    "new_password_err":"Please enter a new password",
    "update":"Update",
    "booking_request":"Booking Requests",
    "profile_settings":"Profile Settings",
    "incomeText":"My Earning",
    "my_bookings":"My Bookings",
    "sign_out":"Log out",
    "rider_ride_cancel_text":"Rider has cancelled the ride",
    "mobile_no_found":"Mobile Number Not found",
    "driver_journey_msg":"Driver started your ride. Your booking id is ",
    "wait_for_rider":"WAIT FOR RIDER",
    "rider_notified":"Rider has been notifed",
    "start_trip":"START TRIP",
    "cancel_reson_1":"Unable to Contact Driver",
    "cancel_reson_2":"Cab is not moving in my direction",
    "cancel_reson_3":"My reason is not listed",
    "cancel_reson_4":"Driver Denied duty",
    "cancel_reson_5":"Cab is not coming on expected time",
    "api_error":"Google places API Error",
    "accept_booking_request":" accepted your booking request.",
    "ignore_text":"Ignore",
    "modal_close":"Modal has been closed.",
    "alert_text":"Alert",
    "ignore_job_title":"Do you want to ignore this job?",
    "accept":"Accept",
    "task_list":"Driver Home",
    "rider_not_here":"NO RIDE REQUESTS AT THE MOMENT",
    "service_off":"YOU SERVICE STATUS IS 'OFF'",
    "booking_request_rejected":"Your booking request has rejected by requested driver",
    "driver_requested_for_payment":"Driver has requested for payment. Your booking id is",
    "driver_completed_ride":"Driver has ended trip. Please complete the payment.",
    "complete_ride":"COMPLETE RIDE",
    "request_payment":"Request Payment",
    "my_rides":"My Rides",
    "password_reset_link":"A Password Reset Link sent to your email please check and reset your New Password.",
    "notification":"Notifications",
    "chat_blank":"please write something...",
    "send_msg":", sent a messege to you: \n",
    "driver":"Driver",
    "chat_history_not_found":"No chat history found",
    "chat":"Chat",
    "send":"Send",
    "type_messege":"Type your messege",
    "password_change":"You Password successfully updated",
    "confrim":"Confrimation",
    "delete_account_question":"Do you want to delete your account ?",
    "my_profile":"My Profile",
    "active_status":"Active Status",
    "profile_details":"Profile Details",
    "location":"Location",
    "mobile_no":"Mobile Number",
    "preffered_lang":"Preferred Language",
    "lang":"English",
    "delete_account":"Delete Account",
    "payment_info":"Rider Payment Information",
    "booking_details":"My Bookings Details",
    "you_rate_text":"You have been rated",
    "no_rated_text":"You have no rating",
    "total_bill":"Total Bill",
    "include_tax":"Includes all Taxes",
    "total_payable":"My Commision",
    "go_to_booking":"Go to Booking",
    "my_booking":"My Bookings",
    "job_ignore":"Do you want to ignore this job?",
    "show_modal":"Show Modal",
    "today":"Today's Earning",
    "thismonth":"This Month's Earning",
    "totalearning":"Total Earning",
    "convenienceFee":"Convenience Fee",
    "paymentStatus":"Payment Status",
    "customer_payable":"Rider Payable",
    "discount_amount":"Discount Amount",
    "upload_image_error":"Uploaded image problem. Please try again.",
    "not_valid_user_type":"Usertype is not valid.",
    "usertype":"User Type",
    "success":"Success",
    "about_us_fetch_failed":"About us fetch failed",
    "no_provider_found":"No Payment Providers Found",
    "provider_fetch_error":"Unable to fetch payment providers",
    "wallet_updated":"Wallet Updated Sucessfully.",
    "place_to_coords_error":"Place to Coordinate error. Please try again.",
    "rider":"Rider",
    "driver":"Driver",
    "camera_permission_error": "Camera Permisison Error",
    "referrerId":"Referrer Id",
    "proper_input": "Please input all the fields properly.",
    "bankAccount":"Bank Account",
    "bankCode":"Account Type",
    "bankName": "Bank Name",
    "require_approval": "Your account requires approval from Admin",
    "referer_not_found":"Referer not found",
    "deactivate_account": "Deactivate Account",
    "image_upload_error": "Image upload error",
    "location_permission_error":"Background Location Permission Error",
    "no_tasks":"No tasks found for driver",
    "active_booking":"ACTIVE BOOKING",
    "close":"Close",
    "searching":"Searching",
    "location_fetch_error":"Location fetch error",
    "driver_cancelled_booking": "DRIVER CANCELLED BOOKING",
    "cancel_confirm":"Do you want really cancel?",
    "navigation_available":"Map Navigation is only available when booking is ACCEPTED or STARTED",
    "location_error":'Unable to get your location',
    "chat_requested": ": Chat Message",
    "distance":"Distance",
    "total_time":"Total Time",
    "order_id":"OrderId ",
    "bookingPayment": "Booking Payment",
    "received_rating": "You received a X star rating", // Do not remove the X while translating
    "withdraw" : "Withdraw",
    "provider_not_found": "No Payment Providers Found.",
    "wallet_zero": "Wallet Balance 0.",
    "withdraw_money": "Withdraw Money",
    "amount": "Amount",
    "valid_amount": "Please enter a valid amount",
    "withdraw_below_zero": "Withdraw amount cannot be less than or equal to 0.",
    "withdraw_more": "Withdraw amount cannot be greater than Wallet Balance.",
    "credited" : "Credited",
    "debited" : "Debited",
    "withdrawn" : "Withdrawn",
    "processed":"Processed",
    "userId": "User ID",
    "processDate" : "Process Date",
    "requestDate": "Request Date",
    "withdraws" : "Withdraws",
    "process_withdraw": "Process Withdraw",
    "not_logged_in" : "Not Logged In"
}