export const MainConfig = {
    AppDetails: {
        app_name: "Kabman", 
        app_description: "Kabman Taxi Booking App",
        app_identifier: "com.kabman.ng.rider",
        ios_app_version: "2.0", 
        android_app_version: 20
    },
    FirebaseConfig: {
        apiKey: "AIzaSyB-WqqmJsDY3uig0jzCm9k5MrRNinslkcw",
        authDomain: "kabman-50c0f.firebaseapp.com",
        databaseURL: "https://kabman-50c0f.firebaseio.com",
        projectId: "kabman-50c0f",
        storageBucket: "kabman-50c0f.appspot.com",
        messagingSenderId: "738184011065",
        appId: "1:738184011065:web:ac141c7558d2bdea789ce8",
        measurementId: "G-98TVTPPCXR"
    },
    Google_Map_Key: "AIzaSyBrgBsr08bIA_D_GAORrbW0Nyp_CzSMMHU",
    facebookAppId: "2343698402425284"
}